import { debounce } from 'throttle-debounce';

/**
 * @todo : replace this with @yanfoo/react-var
 */
import { createReactDict, useReactDict } from '@yanfoo/react-dict';


const STORAGE_KEY = 'localPreferences';
const MAX_STORAGE_SIZE = 4000000; 
const UPDATE_DEBOUNCE = 500;


const localPrefs = createReactDict(JSON.parse(localStorage.getItem(STORAGE_KEY) || null), {
   transform: (newVal, oldVal, changed) => {
      if (changed) {
         updateLocalStorage();
         return newVal;
      } else {
         return oldVal;
      }
   }
});
   
// asynchronously update local storage
const updateLocalStorage = debounce(UPDATE_DEBOUNCE, () => {
   const itemData = JSON.stringify(localPrefs.dictionary);

   if (itemData.length > MAX_STORAGE_SIZE) {
      console.warn('Local preferences exceed maximum storage limit!');
   }

   localStorage.setItem(STORAGE_KEY, itemData);
});
   


const useLocalPreferences = keys => useReactDict(localPrefs, keys);

const getLocalPreference = (key, defaultValue) => localPrefs.dictionary[key] ?? defaultValue;

const setLocalPreference = (key, value) => localPrefs({ [key]: value });



export { useLocalPreferences, getLocalPreference, setLocalPreference };
