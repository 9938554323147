import merge from 'deepmerge';


import { getAccessToken, getFingerprint } from '../util/context/authentication';


const HOST_PATTERN = /(?:erp|app)\.(thermoform\.(?:local(?:host)?|net))/;

const api_location = HOST_PATTERN.test(window?.location?.hostname) 
? merge( window.location, {
   hostname: window.location.hostname.replace(HOST_PATTERN, (_,host) => `api.${host}`)
})
: {
   protocol: 'http:',
   hostname: window.location.hostname,
   port: '4000'
};


export const API_ROOT = [ api_location.protocol, '//', api_location.hostname, api_location.port ? ':' + api_location.port : '' ].join('');

export const FETCH_OPTIONS = {
   method: 'GET',
   credentials: 'include',
   headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-api-version': '1.0',
      get 'x-api-authorization'() { return getAccessToken(); },
      get 'x-api-fingerprint'() { return getFingerprint(); },
      'Origin': API_ROOT
   },
};


export const graphqlConfig = {
   uri: [ API_ROOT, '/graphql' ].join(''),
   credentials: 'include',
   fetch,
   fetchOptions: {
      mode: 'cors',
   },
   headers: {
      "x-api-version": '2.0',
      get 'x-api-authorization'() { return getAccessToken(); },
      get 'x-api-fingerprint'() { return getFingerprint(); },
   }
};