import {
  blue,
  blueGrey,
  brown,
  green,
  indigo,
  lightGreen,
  lightBlue,
  red,
  orange,
  pink,
  purple,
  teal,
  yellow,
} from '@mui/material/colors';



export const THERMOFORM_RED = '#911212';


const COMPONENT_OVERRIDES = {
  MuiAppBar: {
    defaultProps: {
      enableColorOnDark: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '10px 0 10px 0',
      },
    },
    defaultProps: {
      color: 'inherit'
    }
  },
  MuiCheckbox: {
    defaultProps: {
      color: 'default',
    },
  },
  MuiFormControl: {
    defaultProps: {
      color: 'secondary',
    },
  },
  MuiTextField: {
    defaultProps: {
      color: 'secondary',
    },
  },
};



export const DEFAULT_PRESET = 'thermo_light';
export const presets = {
  'thermo_dark': {
    theme: {
      palette: {
        primary: {
          main: blue[800]
        },
        secondary: {
          main: '#80d8ff',  // '#424242',
        },
        error: {
          light: '#ffac33',
          main: '#ff9800',
          dark: '#b26a00',
          contrastText: '#000000'
        },
        background: {
          paper: '#3f3833',
          default: '#26221f'
        },
        mode: 'dark'
      },

      components: COMPONENT_OVERRIDES,

      // custom variants

      typography: {
        info: {
          color: lightGreen[100]
        }
      } 
    },

    palette: [
      blue[300],
      orange[400],
      red[300],
      green[400],
      pink[400],
      yellow[400],
      indigo[400],
      brown[300],
      blueGrey[100],
      teal[400],
      purple[300],
      lightGreen[500],
      blue[600],
      orange[600],
      red[600],
      green[700],
      pink[800],
      yellow[800],
      indigo[800],
      brown[600],
      blueGrey[400],
      teal[700],
      purple[800],
      lightGreen['A700'],
      purple['A700'],
      teal['A700'],
      green['A700'],
      orange[900],
      yellow[100],
      blueGrey[800],
    ]
  },

  'thermo_light': {
    theme: {
      palette: {
        primary: {
          main: lightBlue[600]
        },
        secondary: {
          main: '#424242',
        },
        background: {
          paper: '#eceff1',
          default: '#e0e0e0'
        },

        mode: 'light'
      },

      components: COMPONENT_OVERRIDES,

      typography: {
        info: {
          color: lightGreen[800]
        }
      } 
    },

    palette: [
      blue[300],
      orange[400],
      red[300],
      green[400],
      pink[400],
      yellow[400],
      indigo[400],
      brown[300],
      blueGrey[100],
      teal[400],
      purple[300],
      lightGreen[500],
      blue[600],
      orange[600],
      red[600],
      green[700],
      pink[800],
      yellow[800],
      indigo[800],
      brown[600],
      blueGrey[400],
      teal[700],
      purple[800],
      lightGreen['A700'],
      purple['A700'],
      teal['A700'],
      green['A700'],
      orange[900],
      yellow[100],
      blueGrey[800],
    ]
  }
};
