import { frFR, enUS /*, esES*/ } from '@mui/material/locale';

export default {
   default: 'fr-CA',
   available: ['fr-CA', 'en-CA' /*, 'es-ES'*/ ],
   muiTheme: {
      'fr-CA': frFR,
      'en-CA': enUS,
      //'es-ES': esES,
   }
};
