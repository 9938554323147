import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import merge from 'deepmerge';

import { getDate } from './date-utils';

import { API_ROOT, /*FETCH_OPTIONS,*/ graphqlConfig } from '../.config/api';


const base64pattern = /^data:(.*?);base64,(.*)$/;



const createUrl = path => {
   if (!path || (typeof path !== 'string') || path.startsWith('http') || path.match(base64pattern)) {
      return path;
   } else {
      return `${API_ROOT}/${path}`;
   }
}




const graphqlCache = new InMemoryCache({
   cacheRedirects: {
      Query: {
         //book: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Book', id: args.id })
      },
   },

   typePolicies: {
      // CustomerPriceListElements: {
      //    keyFields: ['sectionId', 'modelGroupId', 'colorGroupId'],
      // }
   }
});


/**
 * Call this function to merge typePolicies with the specified type name
 * 
 * Usage:
 * 
 * setTypePolicies('Foo', {
 *    keyFields: ['foo', 'bar'],         // set keys for this model in cache
 *    fields: {
 *       items: {
 *          merge: false
 *       }
 *    }
 * });
 * 
 * See: https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields
 * 
 * @param {string} typeName 
 * @param {any} policies 
 */
const setTypePolicies = (typeName, policies) => {
   graphqlCache.policies.typePolicies[typeName] = merge(
      graphqlCache.policies.typePolicies?.[typeName] ?? { fields: {} }, 
      policies
   );
}




const apolloClient = new ApolloClient({
   link: new HttpLink(graphqlConfig),
   cache: graphqlCache,

   name: 'erp-client',
   version: '1.2',
   queryDeduplication: true,   // reuse inflight queries
   defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
   },
   //request: operation => {
   //   operation.setContext({
   //      credentials: FETCH_OPTIONS.credentials,
   //   })
   //}
});




const $inputType = Symbol.for('$inputType');
/**
 * Create a typed input value that will proxy the default value and field name
 * 
 * Usage:
 * 
 * // 1. Given a fieldName and defaultValue (a function), how should model be handled to return a value
 * const typeHandler = createInputModelType((fieldName, defaultValue) => model => model?.[fieldName] ?? defaultValue())
 * // 2. Provide a default value for the typeHandler and return a wrapper
 * const fieldHandler = typeHandler('default placeholder');
 * // 3. Define the property name to handler using the default value and the type handler
 * const valueHandler = fieldHandler('foo');   // create a handler for property 'foo'
 * 
 * const value1 = valueHandler({ foo: true })  // -> true
 * const value2 = valueHandler({})             // -> 'default placeholder'
 * 
 * 
 * @param {(fieldName:string, ...args:any[]) => (model:any, options:any) => any} handler 
 * @returns {(...args:any[]) => { [$inputType]: (fieldName:string, ...args:any[]) => (model:any, options:any) => any }}
 */
const createInputModelType = (handler, validations) => {
   function modelValue(...args) {
      const options = { args };
      const ctx = { [$inputType]: fieldName => handler(fieldName, options) };

      if (validations && typeof validations === 'object') {
         for (const validation in validations) {
            ctx[validation] = (...args) => {
               validations[validation](options, ...args);
               return ctx;
            };
         }
      }

      return ctx;
   };

   return modelValue;
};

/**
 * Convenient way to return a default value
 * @param {() => any|any?} defaultValue 
 * @param {model:any} model
 * @returns {any}
 */
const getDefaultValue = (defaultValue, model) => typeof defaultValue === 'function' ? defaultValue(model) : defaultValue;

const inputModelTypeDef = {

   /**
    * Return a clean input model to send to GraphQL
    * 
    * The fields argument should be an object mapping a model to an input model that will be sent
    * to GraphQL. For example:
    * 
    *    {
    *       "foo": true,                              // use value as is
    *       "bar": false,                             // ignore field
    *       "buz": model => model?.buz?.id,           // get a different field
    *       "qty": inputModelTypeDef.type.integer(0), // ensure integer value, or 0
    *    }
    * 
    * Will return an object with at least two keys : foo, bar, where buz is optional depending on whether or not
    * the model has a property Buz containing a property id with a value. The returned input model will never
    * provide a field with an undefined value.
    * 
    * If fields are optionally evaluated, a second argument can be passed to the fields' callback functions.
    * 
    * const builder = inputModelTypeDef.build({ 
    *    foo: (_, { create }) => create }),
    *    bar: (_, { create }) => !create }),
    *    buz: model => model?.buz
    * });
    * 
    * builder({ foo: 'hello', bar: 'world', buz: '!!' }, { create: true });
    * // -> { foo: 'hello', buz: '!!' }
    * builder({ foo: 'hello', bar: 'world', buz: false }, { create: false });
    * // -> { bar: 'world' }
    * 
    * @param {any} fieldMap    a map of fields that will compose the input model
    * @return {(model:any, options:any) => any}          a function accepting a single argument (model) returning a cleaned clone of it
    */
   build(fieldMap) {
      const fields = Object.keys(fieldMap);

      for (const fieldName of fields) {
         if (fieldMap[fieldName]?.[$inputType]) {
            fieldMap[fieldName] = fieldMap[fieldName][$inputType](fieldName);
         }
      }
   
      return (model, options) => {
         if (model && (typeof model === 'object') && !Array.isArray(model)) {
            const input = {};

            for (const fieldName of fields) {
               const value = typeof fieldMap[fieldName] === 'function' 
                  ? fieldMap[fieldName](model, options) 
                  : fieldMap[fieldName] ? model[fieldName] : undefined
               ;
                     
               if (value !== undefined) {
                  input[fieldName] = value;
               }
            }

            return input;
         } else {
            return null;
         }
      }
   },


   type: {

      /**
       * Convert to any value, with default value fallback
       * 
       * @type {(model:any, options:any) => any}
       */
      any: createInputModelType((fieldName, { args: [ defaultValue ] }) => model => model?.[fieldName] ?? getDefaultValue(defaultValue, model)),

      /**
       * Convert to a boolean value
       * 
       * @type {(model:any, options:any) => any}
       */
      boolean: createInputModelType((fieldName, { args: [ defaultValue ] }) => model => fieldName in model ? Boolean(model[fieldName]) : getDefaultValue(defaultValue, model)),


      /**
       * Define a field that is a dictionary key. 
       * 
       * Usage:
       * 
       * const inputTypeDef = inputModelTypeDef.build({
       *    dictLabel: inputModelTypeDef.type.dictionaryKey()
       *      // or
       *    dictLabel: inputModelTypeDef.type.dictionaryKey(0)   // default value = 0
       * });
       * 
       * const dictionaryKeys = new Set();
       * const model = { dictLabel: '1234' };
       * 
       * 
       * const inputModel = inputTypeDef(model, {
       *    onDictionaryKey(value, _fieldName, _model) {
       *       dictionaryKeys.add(value);
       *    }
       * });
       * // inputModel = { dictLabel:'1234' }
       * // dictionaryKeys = Set(['1234'])
       * 
       * @type {(model:any, options:any) => any}
       */
      dictionaryKey: createInputModelType((fieldName, { args: [ defaultValue ] }) => (model, options) => {
         const value = model?.[fieldName];
         const isEmpty = (value === void 0) || (value === null) || (typeof value === 'string' && value.trim().length === 0);

         if (!isEmpty) {
            if (options?.onDictionaryKey) {
               return options.onDictionaryKey(value, fieldName, model) ?? value;
            } else {
               return value;
            }
         } else {
            return getDefaultValue(defaultValue, model);
         }
      }),

      /**
       * Export a date as string (ISO) input value, prserving only the date part
       * 
       * @type {(model:any, options:any) => any}
       */
      date: createInputModelType((fieldName, { args: [ defaultValue ] }) => model => getDate(model?.[fieldName])?.toISOString?.()?.substring?.(0, 10) ?? getDefaultValue(defaultValue, model)),

      /**
       * Export a date as string (ISO) input value
       * 
       * @type {(model:any, options:any) => any}
       */
      datetime: createInputModelType((fieldName, { args: [ defaultValue ] }) => model => getDate(model?.[fieldName])?.toISOString?.() ?? getDefaultValue(defaultValue, model)),

      /**
       * Convert to a number value
       * 
       * Usage:
       * 
       *    inputModelTypeDef.type.number(defaultValue)
       *       .min(minValue)           // optional
       *       .max(maxValue)           // optional
       * 
       * @type {(model:any, options:any) => any}
       */
      number: createInputModelType((fieldName, { args: [ defaultValue ], min, max }) => model => {
         let value = parseFloat(model?.[fieldName], 10);
         
         if (isNaN(value)) value = getDefaultValue(defaultValue, model);
         if (!isNaN(min) && value < min) value = min;
         if (!isNaN(max) && value > max) value = max;

         return value;
      }, {
         min: (options, value) => options.min = value,
         max: (options, value) => options.max = value,
      }),

      /**
       * Convert to an integer, discarding decimals if any
       * 
       * Usage:
       * 
       *    inputModelTypeDef.type.integer(defaultValue)
       *       .min(minValue)           // optional
       *       .max(maxValue)           // optional
       * 
       * @type {(model:any, options:any) => any}
       */
      integer: createInputModelType((fieldName, { args: [ defaultValue ], min, max }) => model => {
         let value = parseInt(model?.[fieldName], 10);
         
         if (isNaN(value)) value = getDefaultValue(defaultValue, model);
         if (!isNaN(min) && value < min) value = min;
         if (!isNaN(max) && value > max) value = max;

         return value;
      }, {
         min: (options, value) => options.min = value,
         max: (options, value) => options.max = value,
      }),
      
      /**
       * Map the given field as array.map
       * 
       * Usage:
       * 
       * const inputTypeDef = inputModelTypeDef.build({
       *    items: inputModelTypeDef.type.map(item => item.id, [])
       *      // equivalent with
       *    items: ({ items }) => items?.length ? items.map(item => item.id) : []
       * });
       * 
       * const model = { items: [
       *    { id:1, name:'Foo' },
       *    { id:2, name:'Bar' },
       * ] };
       * 
       * const inputModel = inputTypeDef(model);
       * // inputModel = { items: [1, 2] }
       * 
       * @type {(model:any, options:any) => any}
       */
      map: createInputModelType((fieldName, { args: [ predicate, defaultValue ] }) => model => model?.[fieldName]?.length ? model[fieldName].map(predicate) : getDefaultValue(defaultValue, model)),

   }
}


export {
   apolloClient,
   inputModelTypeDef,

   setTypePolicies,
   createUrl,
};