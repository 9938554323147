import { createTheme, responsiveFontSizes, darken, lighten } from '@mui/material/styles';
import merge from 'deepmerge';

import localeConfig from '../.config/i18n';

import { DEFAULT_PRESET, presets } from '../.config/theme';


//console.log( red[500], lighten(red[500], 0) );


/**
 * Return all defined presets from the configuration
 * 
 * @return {Array<String>}
 */
export const getPresets = () => Object.keys(presets);


/**
 * Generate the theme
 * 
 * - options                the configuration option for the theme
 * - options.preset         the theme preset to use (default: 'thermo_dark')
 * 
 * @param {String} preset      the preset name (see `getPresets()`)
 * @return {Object}            a Material UI theme
 */
// https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=911212&secondary.color=424242
export const getTheme = (preset, locale) => {
   const themePreset = presets[preset || DEFAULT_PRESET].theme;
   const localizedTheme = localeConfig.muiTheme[locale];
   const theme = responsiveFontSizes(createTheme(themePreset)); // adaptV4Theme(themePreset, localeProps)));

   return merge(localizedTheme, theme);
};


export const getPalette = preset => {
   return presets[preset || DEFAULT_PRESET]?.palette ?? [];
};



/**
 * Make any scrollbar appear with current theme colors. Also, apply width in pixels
 * 
 * Usage : 
 * 
 *   const useStyles = makeStyles(theme => ({
 * 
 *     root: {
 *       overflow: 'scroll',
 *       ...themedScrollbars(theme(theme)
 *     }
 * 
 *   }));

 * 
 * @param {Object} theme          Material UI palette
 * @param {Number|String} size
 * @return {Object}
 */
export const themedScrollbars = (theme, size = 6) => {
   if (typeof size === 'number') {
      size = `${size}px`;
   }

   return {
      scrollbarWidth: size,
      scrollbarColor: theme.palette.text.secondary + ' ' + theme.palette.divider,
      '&::-webkit-scrollbar': {
         width: size,
         height: size,
      },
      '&::-webkit-scrollbar-button': {
         display: 'none'
      },
      '&::-webkit-scrollbar-track': {
         backgroundColor: theme.palette.mode === 'dark' ? darken(theme.palette.text.secondary, 0.6) : lighten(theme.palette.text.secondary, 0.6)
      },
      '&::-webkit-scrollbar-track:hover': {
         backgroundColor: theme.palette.mode === 'dark' ? darken(theme.palette.text.secondary, 0.55) : lighten(theme.palette.text.secondary, 0.5)
      },
      '&::-webkit-scrollbar-thumb': {
         backgroundColor: theme.palette.mode === 'dark' ? darken(theme.palette.text.primary, 0.3) : lighten(theme.palette.text.primary, 0.5),
         ...theme.shape
      },
      '&::-webkit-scrollbar-thumb:hover': {
         backgroundColor: theme.palette.mode === 'dark' ? darken(theme.palette.text.primary, 0.2) : lighten(theme.palette.text.primary, 0.4),
      },
   };
};



export const themedDisabled = (_theme, disabled) => ({

   opacity: disabled ? 0.6 : 1,
   '& img': {
      filter: disabled ? 'grayscale(80%)' : 'none'
   }

});



export const themedBackground = (theme, { light } = {}) => ({
   backgroundColor: theme.palette.background.paper,
   backgroundImage: theme.palette.mode === 'dark' ? `linear-gradient(rgba(255, 255, 255, ${light ? '0.16' : '0.09'}), rgba(255, 255, 255, ${light ? '0.16' : '0.09'}))` : null
});