import dateAdd from 'date-fns/add';
import dateParseISO from 'date-fns/parseISO';
import toDate from 'date-fns/toDate';
import isValidDate from 'date-fns/isValid';

import frCA_localeFns from 'date-fns/locale/fr-CA';
import enUS_localeFns from 'date-fns/locale/en-US';

import i18n from "i18next";

import { formatNumber } from './number-format';


export const DATE_LOCALES = {
   fr: frCA_localeFns,
   en: enUS_localeFns
};



export const getDateLocale = locale => {
   locale = locale ?? i18n.language;
   locale = locale?.length > 2 ? locale.split('-')[0] : locale;

   return DATE_LOCALES[locale] ?? DATE_LOCALES.en;
}


/**
 * Returns a Date instance of null.
 * 
 * If date is a string, it will be parsed as a ISO date;
 * If date is a number, it will be parsed as a timestamp;
 * 
 * If date is invalid, null is returned.
 * 
 * @param {number|string|Date|null} date 
 * @returns {Date|null}
 */
export const getDate = date => {
   if (typeof date === 'string') {
      date = dateParseISO(date);
   } else if (!(date instanceof Date)) {
      date = toDate(date);
   }

   return isValidDate(date) ? date : null;
};




/**
 * Return true if the given date is a working day. Return false if
 * it is a weekend.
 * @param {any} day 
 */
export const isWorkingDay = day => {
   let valid = false;
   let weekday = 0;

   if (day) {
      
      // TODO : add holidays and other special days??

      if (day instanceof Date) {
         weekday = day.getDay();
         valid = !isNaN(weekday) && (weekday > 0) && (weekday < 6);
      } else {
         throw new TypeError(`Invalid day, ${day} received`);
      }
   }

   return valid && (weekday > 0) && (weekday < 6);
};



/**
 * Return true for all dates outside of normal working hours
 * 
 * @param {any} day
 * @return {Boolean}
 */
export const disableNonWorkingDays = day => !isWorkingDay(day);


/*
const now = new Date();
const d1 = (24 * 24 * 60 * 60 * 1000);
const duration = intervalToDuration({ start:0, end:d1 });
const d2 = add(now, duration);


console.log( d1 );
console.log( duration );
console.log( d2 );
console.log( millisecondsToHours(d2.getTime() - now.getTime()) / 24 );
*/


const DAY_TO_HOURS = 24;
//const YEAR_TO_HOURS = DAY_TO_HOURS * 365.25;  // round off 365.25
//const MONTH_TO_HOURS = YEAR_TO_HOURS / 12;  // round off 28/29 - 31
const MINUTES_TO_HOURS = 1 / 60;
const SECONDS_TO_HOURS = 1 / 3600;
const APPROX_HOURS = DAY_TO_HOURS * 28;  // more than 28 days


const MILLISECONDS_TO_SECONDS = 1000;
const MILLISECONDS_TO_MINUTES = 60 * MILLISECONDS_TO_SECONDS;
const MILLISECONDS_TO_HOURS = 60 * MILLISECONDS_TO_MINUTES;


/**
 * Returns a human readable representation of this duration. This function will
 * also add an approx symbol (~) if the duration is about, or greater than, a
 * month. This is because the actual duration cannot be safely determined without
 * a starting month.
 * 
 * Ex: formatDurationHours( intervalToDuration({ start: 0, end: 4000063441 }) )
 *     -> '~1097:07:43'
 * 
 * @param {Duration} duration       a date-fns Duration object
 * @param {Object} params           options
 * @returns {String}
 */
export const formatDurationHours = (
   duration,
   {
      startDate = new Date(),
      leadingZeros = true,
      paddedZeros = true,
      separators: { hours:sepHours = ':', minutes:sepMinutes = ':', seconds:sepSeconds = '' } = {},
      showSeconds = true
   } = {}
) => {
   const millis = dateAdd(startDate, duration).getTime() - startDate.getTime();
   const hours = (millis / MILLISECONDS_TO_HOURS) | 0;
   const minutes = ((millis % MILLISECONDS_TO_HOURS) / MILLISECONDS_TO_MINUTES) | 0;
   const seconds = ((millis % MILLISECONDS_TO_MINUTES) / MILLISECONDS_TO_SECONDS) | 0;

   /*
   const hours = (((duration?.years ?? 0) * YEAR_TO_HOURS) + ((duration?.months ?? 0) * MONTH_TO_HOURS) + ((duration?.days ?? 0) * DAY_TO_HOURS) + (duration?.hours ?? 0)) | 0;
   const minutes = duration?.minutes ?? 0;
   const seconds = duration?.seconds ?? 0;
   */
  const sHours = (paddedZeros && hours < 10 ? ('0' + hours).substr(-2) : String(hours));
  const sMinutes = paddedZeros && minutes < 10 ? ('0' + minutes).substr(-2) : String(minutes);
  const sSeconds = paddedZeros && seconds < 10 ? ('0' + seconds).substr(-2) : String(seconds);

   let formatted = '';

   if (showSeconds && seconds) {
      formatted = `${sSeconds}${sepSeconds}`;
   }
   if (leadingZeros || minutes || hours) {
      formatted = `${sMinutes}${sepMinutes}` + formatted;
   }
   if (leadingZeros || hours) {
      formatted = `${sHours}${sepHours}` + formatted;
   }

   return formatted
};


/**
 * Format this duration as a decimal value. The difference with this function and simply
 * using numberFormat(durationToHours(duration)) is that this function will add an approx
 * symbol (~) if the duration is about, or greater than, a month. This is because the
 * actual duration cannot be safely determined without a starting month.
 * 
 * @param {Duration} duration        a date-fns Duration object
 * @param {Number|Array} precision   the decimal precision (see formatNumber)
 * @returns {String}
 */
export const formatDurationHoursAsNumber = (duration, precision = 4) => {
   const hours = durationToHours(duration);

   return (hours > APPROX_HOURS ? '~' : '') + formatNumber(hours, precision);
};


/**
 * Return the given duration in terms of hours, using decimal digits representing the
 * fractional minutes and seconds.
 * 
 * Ex: durationToHours({ hours: 1, minutes: 30, seconds: 0 })
 *     -> 1.5
 * 
 * @param {Duration} duration       a date-fns Duration object
 * @returns {Number}
 */
export const durationToHours = (duration, startDate = new Date()) => {
   const millis = dateAdd(startDate, duration).getTime() - startDate.getTime();
   const hours = (millis / MILLISECONDS_TO_HOURS) | 0;
   const minutes = ((millis % MILLISECONDS_TO_HOURS) / MILLISECONDS_TO_MINUTES) | 0;
   const seconds = ((millis % MILLISECONDS_TO_MINUTES) / MILLISECONDS_TO_SECONDS) | 0;

   return hours + (minutes * MINUTES_TO_HOURS) + (seconds * SECONDS_TO_HOURS);
};