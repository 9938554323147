import { useMemo } from 'react';
import deepEqual from'fast-deep-equal';

import { createReactVar } from '@yanfoo/react-var';


const AUTH_SCRIPT_SRC = process.env.REACT_APP_AUTH_SCRIPT;


const STATE_GUEST = 0;
const STATE_PENDING = 1;
const STATE_AUTHENTICATED = 2;


const REVALIDATE_TIMEOUT = 1000 * process.env.NODE_ENV === 'development' ? 1 : 60 * 15;   // 15 minutes

const ACCESS_TOKEN_STORAGE_KEY = 'thermo.auth.accessToken';


const internal = {};


const persistAccessToken = ({ accessToken, user, timestamp }) => localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, JSON.stringify({ accessToken, user, timestamp }));
const restoreAccessToken = () => {
   try {
      return JSON.parse(localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) ?? null) || {};
   } catch (e) {
      return {};
   }
};

const isAccessTokenTimeout = timestamp => (Date.now() - (timestamp || 0)) > REVALIDATE_TIMEOUT;


const authState = createReactVar(() => { 
   const { accessToken = null, user = null, timestamp = 0 } = restoreAccessToken();

   return {
      state: STATE_PENDING,
      accessToken,
      fingerprint: null,  // require auth script to load...
      user,
      timestamp,
      error: false
   };
}, { comparator: (a, b) => (a.state === b.state) && (a.accessToken === b.accessToken) && deepEqual(a.user, b.user) });

authState.subscribe(({ value: { accessToken, user, timestamp } }) => persistAccessToken({ accessToken, user, timestamp }));



const validateAccessToken = async () => {
   const { accessToken, timestamp } = authState.value;
   const revalidate = isAccessTokenTimeout(timestamp);

   if ((!accessToken || revalidate) && !window.Thermoform.auth.__validation) {
      if (window.Thermoform.auth.__updateTimeout) {
         clearTimeout(window.Thermoform.auth.__updateTimeout);
      }
      
      window.Thermoform.auth.__updateTimeout = setTimeout(validateAccessToken, REVALIDATE_TIMEOUT);
      window.Thermoform.auth.__validation = window.Thermoform.auth.authenticate().then(({ accessToken, user } = {}) => {
         if (accessToken) {
            return { accessToken, user };
         } else {
            return {};
         }
      }, (err) => {
         console.error("*** validateAccessToken:Error", err);

         return { error:true };
      }).then(({ accessToken = null, user = null, error = false }) => {
         if (!accessToken || !user || error) {
            signOut();

            return authState.value;
         } else {
            const state = accessToken ? STATE_AUTHENTICATED : STATE_GUEST;
            const timestamp = Date.now();

            return authState(prevValue => ({ ...prevValue, state, accessToken, user, timestamp, error }));
         }
      }).finally(() => {
         window.Thermoform.auth.__validation = null;
      });

      return window.Thermoform.auth.__validation;
   } else {
      return window.Thermoform.auth.__validation ?? authState(prevValue => ({ 
         ...prevValue,
         state: prevValue.accessToken && prevValue.user ? STATE_AUTHENTICATED : STATE_GUEST
      }));
   }
}


const initAuthenticator = async () => {
   if (!internal.init) {
      internal.init = new Promise(resolve => {
         const body = document.getElementsByTagName('body')[0];
         const tag = document.createElement('script');

         tag.async = true;
         tag.src = AUTH_SCRIPT_SRC;
         tag.onload = resolve;
         body.appendChild(tag);
      }).then(async () => {
         // first thing we set is the browser fingerprint... then we validate the access token
         const fingerprint = await window.Thermoform.auth.getFingerprint();

         return authState(prevValue => ({ ...prevValue, fingerprint })).then(() => validateAccessToken());
      }).catch(err => {
         console.error(err);
         signOut();
      });
   }

   return internal.init;
};




const useAuthenticationContext = () => {
   const value = authState.useValue();  // reactive

   return useMemo(() => ({
      get ready() { return value.state === STATE_GUEST || value.state === STATE_AUTHENTICATED; },
      get user() { return value.user; },
      get accessToken() { return value.accessToken; },
      get error() { return value.error; }
   }), [value]);
};


/**
 * Returns the authentication fingerprint
 * @returns {string}
 */
const getFingerprint = () => authState.value.fingerprint;

/**
 * @returns the current non-reactive accessToken
 */
const getAccessToken = () => authState.value.accessToken ?? '';

/**
 * @returns the current non-reactive user authenticated, or null otherwise
 */
const getActiveUser = () => authState.value.user;


const userProfile = () => {
   if (window?.Thermoform?.auth) {
      window.Thermoform.auth.profile();
   }
}


/**
 * Redirects to the sign in page
 */
const signIn = async () => {
   if (window?.Thermoform?.auth) {
      return window.Thermoform.auth.signIn();
   }
};

/**
 * Redirects to the sign out page
 */
const signOut = async () => {
   persistAccessToken({});

   if (window?.Thermoform?.auth) {
      await window.Thermoform.auth.signOut();
   }

   return signIn();
};




//export default AuthenticationContext;
export { 
   initAuthenticator,
   
   useAuthenticationContext, 

   getActiveUser,
   getAccessToken,
   getFingerprint,

   userProfile,
   signIn,
   signOut
};