//import { useEffect, useMemo, useReducer } from 'react';
import i18n from "i18next";
import languageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next, useTranslation } from "react-i18next";
import moment from 'moment';
import countriesISO from "i18n-iso-countries";
import ucfirst from 'ucfirst';

import { createReactVar } from '@yanfoo/react-var';

import config from '../.config/i18n';

import MomentUtils from '@date-io/moment';



class LocaleLoader {
   loaded = false;
   data = null;

   constructor(loader) {
      this.loader = loader;
   }

   async getData() {
      if (!this.loaded) {
         const module = await this.loader();
         this.data = module.default;
         this.loaded = true;
      }

      return this.data;
   }
}



// we need to explicitly define the following imports so they are optimized from the build process
// this also prevents from including ALL locales in the build...
const momentLocaleLoaders = new Map(Object.entries({
   'fr-CA': () => import('moment/locale/fr-ca'),
   'en-CA': () => import('moment/locale/en-ca'),
}));


const i18nISOCountriesLoaders = new Map(Object.entries({
   'fr': new LocaleLoader(() => import(`i18n-iso-countries/langs/fr.json`)),
   'en': new LocaleLoader(() => import(`i18n-iso-countries/langs/en.json`)),
}))

const dateFnsLocales = new Map(Object.entries({
   'fr-CA': new LocaleLoader(() => import('date-fns/locale/fr-CA')),
   'en-CA': new LocaleLoader(() => import('date-fns/locale/en-CA')),
}))




const getValidLocale = locale => {
   locale = locale ?? i18n?.language ?? config.default;

   return locale && (locale !== 'default') && config.available.includes(locale) ? locale : getValidLocale(locale);
};

const getAvailableLocales = () => config.available.filter(locale => locale !== 'default');


const currentLocale = createReactVar(getValidLocale());
const currentDateFnsLocale = createReactVar(null);



const formatters = {
   uppercase: value => String(value).toLocaleUpperCase(),
   lowercase: value => String(value).toLocaleLowerCase(),
   bold: value => `<strong>${value}</strong>`,
   quote: (value, params) => {
      const q = params[0] || '"';
      const replace = '\\' + q;
      let startIndex = 0;
      let endIndex;

      value = String(value);

      while ((endIndex = value.indexOf(q, startIndex)) >= 0) {
         value = value.substring(startIndex, endIndex) + replace + value.substring(endIndex + q.length);
         startIndex = endIndex + replace.length;
      }

      return `${q}${value}${q}`;
   },
   format: (value, params) => value.format ? value.format(...params) : value,
   ucfirst: value => ucfirst(value),
};


i18n.FORMAT_SEPARATOR = '|';
i18n.FORMAT_PARAMS_SEPARATOR = '?';


i18n
   .use(languageDetector)
   .use(initReactI18next) // passes i18n down to react-i18next
   .use(intervalPlural)
   .init({
      ns: ['dictionary'],
      defaultNS: 'dictionary',
      lng: config.default,
      fallbackLng: config.default,
      languages: config.available,
      whitelist: config.available,
      //debug: true,

      partialBundledLanguages: true,

      keySeparator: '.', // allow "path.to.message" to match { "path": { "to": { "message": "foo" } } }

      detection: {
         checkWhitelist: true
      },

      interpolation: {
         escapeValue: false, // react already safes from xss
         formatSeparator: '>',
         format(value, format, locale) {
            const formatStack = parseFormatters(format);
            
            if (value instanceof Date) value = moment(value);
            
            while (formatStack.length) {
               const next = formatStack.pop();
               
               if (next.format in formatters) value = formatters[next.format](value, next.params, locale);
            }

            return value;
        }
      },

      react: {
         bindI18n: 'languageChanged',
         //bindI18nStore: 'added',
         useSuspense: false
      }
   })
;


const parseFormatters = format => format.split(i18n.FORMAT_SEPARATOR).map(formatParam => {
   const [ format, params = '' ] = formatParam.split(i18n.FORMAT_PARAMS_SEPARATOR).map(s => s.trim());

   return {
      format,
      params: params ? params.split(',').map(p => p.trim()) : []
   };
});



/*

   ISO Countries and Moment Configuration

*/
const setLocale = async locale => {
   if (!locale) return;

   // load locale bundles
   if (localeBundles.has(locale)) {
      try {
         await Promise.all(localeBundles.get(locale).map(bundles => loadResourceBundles(locale, bundles)));

         localeBundles.delete(locale);  // reset so we do not load them twice
      } catch (err) {
         console.warn(`Counld not load module messages bundle for locale: ${locale}`);
         console.error(err);
      }
   }

   /** @deprecated */
   moment.locale(locale);
   if (momentLocaleLoaders.has(locale)) {
      try {
         await momentLocaleLoaders.get(locale)();  // import

         momentLocaleLoaders.delete(locale);  // cleanup
      } catch (err) {
         console.warn(`Counld not load moment for locale: ${locale}`);
         console.error(err);
      }
   }
   // ............

   // date-fns
   if (dateFnsLocales.has(locale)) {
      currentDateFnsLocale(await dateFnsLocales.get(locale).getData());
   }

   // countriesISO
   if (!countriesISO.langs().includes(locale)) {
      try {
         const language = locale.split('-').shift().toLocaleLowerCase();

         if (i18nISOCountriesLoaders.has(language)) {
            const countries = await i18nISOCountriesLoaders.get(language).getData();

            // register both ISO and alpha-2 codes
            countriesISO.registerLocale({ locale: locale.toLocaleLowerCase(), countries });
            countriesISO.registerLocale({ locale: language, countries });

            // do not reload this
            i18nISOCountriesLoaders.delete(language);  // cleanup
         }
      } catch (err) {
         console.warn(`Counld not load country names for locale: ${locale}`);
         console.error(err);
      }
   }

   currentLocale(locale);   

   i18n.changeLanguage(locale);  // force update
};
//i18n.on('languageChanged', locale => setLocaleGlobal(locale));


const localeBundles = new Map();

/**

Locales loaders. The function *must* return an object, which keys are the namespace and
the values are the resolvable messages in the bundle.

For example :

   registerLocaleBundles('fr-CA', {
      'mynamespace': () => import('path/to/json')
   });


@param local {String}
@param bundles {Array<Object<string,AsyncFunction|Function>}
*/
const registerLocaleBundles = async (locale, bundles) => {
   if (!locale) {
      throw new Error(`Invalid locale : ${locale}`);
   } else if (locale === currentLocale.value) {
      return loadResourceBundles(locale, bundles);
   } else {
      // stack for later...
      if (!localeBundles.has(locale)) {
         localeBundles.set(locale, []);
      }
      localeBundles.get(locale).push(bundles);
   }
}


const loadResourceBundles = async (locale, bundles) => {
   for (const [ ns, loader ] of Object.entries(bundles || {})) {
      const messages = await loader();
      i18n.addResourceBundle(locale, ns, messages.default, true, true);
   }
}


/**
 * Make sure we have a moment instance from the given value
 * 
 * @deprecated
 * @param {String|Number|Date} d
 * @return {Moment}
 */
const getMoment = (d, locale) => {
   const m = d ? moment(isNaN(d) ? d : Number(d)) : moment();
   // make sure we use the specified locale
   if (locale) {
      m.locale(locale);
   }

   console.error('Deprecation error: Moment.js');

   return m;
};


/**
 * Simple HOC that will re-render the component when the locale changes, for
 * components that don't use useTranslator
 * 
 * @return {Object}         { locale }
 */
const useLocale = () => currentLocale.useValue();

/**
 * Non-reactive function to get the current locale
 * @returns {String}
 */
const getLocale = () => currentLocale.value;


const getTranslation = ns => i18n.getFixedT(currentLocale.value, ns);



/**
 * HOC to retrive the data-fns localization
 * @returns     current date-fns localization data
 */
const useLocaleDate = () => currentDateFnsLocale.useValue();


// init

setLocale(currentLocale.value);


export { 
   getValidLocale, getAvailableLocales,

   useTranslation, getTranslation, registerLocaleBundles,

   useLocale, getLocale, setLocale,

   useLocaleDate,  
};

// TODO : remove
export {
   getMoment,
   
   /** @deprecated */
   moment,
   
   /** @deprecated */
   MomentUtils
};