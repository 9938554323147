import React, { createContext, useContext, useMemo } from 'react';



const NavigationContext = createContext({
   handler: message => { throw new Error('Missing NavigationProvider'); }
});



const PreventNavigationProvider = ({ children }) => {

   const contextValue = useMemo(() => {
      const beforeUnloadHandler = event => {
         event.preventDefault();
         event.returnValue = preventMessage;
         return preventMessage;
      };
      let prevented = false;
      let preventMessage;

      return {
         handler: message => {
            const shouldPrevent = !!message;

            if (shouldPrevent !== prevented) {
               if (shouldPrevent) {
                  //const unblock = history.block(message);
                  window.addEventListener('beforeunload', beforeUnloadHandler);
               } else {
                  //unblock();
                  window.removeEventListener('beforeunload', beforeUnloadHandler);
               }

               prevented = shouldPrevent;
            }

            preventMessage = message;
         }
      };
   }, []);

   return (
      <NavigationContext.Provider value={ contextValue }>
         { children }
      </NavigationContext.Provider>
   );
};


const usePreventNavigation = () => useContext(NavigationContext).handler;


export default PreventNavigationProvider;
export { usePreventNavigation };