import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';

import * as serviceWorker from './serviceWorker';

import AllProviders from './application/components/layout/AllProviders';

import { initAuthenticator } from './application/util/context/authentication';

import 'animate.css';
import 'whatwg-fetch'; // make sure we have fetch!

//import Router from './router';
const ApplicationLayout = lazy(() => import('./application/components/layout/ApplicationLayout'));
const ErrorLayout = lazy(() => import('./application/components/layout/ErrorLayout'));



class Application extends React.Component {

   static getDerivedStateFromError(error) {
      return { error };
   }
   
   state = { error: null };

   componentDidCatch(error, errorInfo) {
      console.error(error, errorInfo);
   }


   render() {
      const error = this.props.error ?? this.state.error ?? null;
      const hasError = !!error;
      const LayoutComponent = hasError ? ErrorLayout : ApplicationLayout;

      if (hasError) {
         console.error(error);
      }

      // TODO : show error information if in dev mode

      return (
         <Suspense fallback={ null }>
            <AllProviders>
               <LayoutComponent />
            </AllProviders>
         </Suspense>
      );
   }
};



const getAppRoot = () => createRoot(document.getElementById('app'));


Promise.all([                 // post init...
   initAuthenticator()
]).then(() => Promise.all([   // bootstrap all modules before initializing router...
   import('./application/bootstrap'),
   import('./modules/system/bootstrap'),
   import('./modules/admin/bootstrap'),
   import('./modules/hr/bootstrap'),
   import('./modules/production/bootstrap'),
   import('./modules/customer-center/bootstrap'),
].map(p => p.then(({ default:bootstrap }) => bootstrap()))).then(() => {
   getAppRoot().render( <Application /> );
}).catch(err => {
   getAppRoot().render( <Application error={ err } /> );
}));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
