import React, { useMemo } from 'react';

import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { BrowserRouter } from "react-router-dom";

import PermissionsProvider from '../../util/context/security';
import PreventNavigationProvider from '../../util/context/prevent-navigation';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useLocalPreferences } from '../../util/context/local-preferences';
import { useLocale, useLocaleDate } from '../../util/i18n';

import { getTheme } from '../../util/theme';

import { apolloClient } from '../../util/api';



/**
 * Define providers from most static (parent) to most dynamic (child)
 */
const AllProviders = ({ children }) => {
   const { theme } = useLocalPreferences(['theme']);
   const locale = useLocale();
   const localeDate = useLocaleDate();
   const muiTheme = useMemo(() => getTheme( theme, locale ), [theme, locale]);

   return (
      <StyledEngineProvider injectFirst>
         <HelmetProvider>
            <BrowserRouter>
               <PreventNavigationProvider>
                  <ApolloProvider client={ apolloClient }>
                     <ThemeProvider theme={ muiTheme }>
                        <CssBaseline />
                        <PermissionsProvider>
                           <LocalizationProvider dateAdapter={ AdapterDateFns } adapterLocale={ localeDate }>
                              {  children }
                           </LocalizationProvider>
                        </PermissionsProvider>
                     </ThemeProvider>
                  </ApolloProvider>
               </PreventNavigationProvider>
            </BrowserRouter>
         </HelmetProvider>
      </StyledEngineProvider>
   );
};


export default AllProviders;