import { getLocale } from './i18n';





const getPrecision = precision => {
   if (Array.isArray(precision)) {
      return {
         minimumFractionDigits: precision[0],
         maximumFractionDigits: precision[1]
      }
   } else if (!isNaN(precision)) {
      return {
         minimumFractionDigits: precision,
         maximumFractionDigits: precision
      }
   } else {
      return {};
   }
}


/**
 * @todo get authenticated user's default currency
 */
export const DEFAULT_CURRENCY = 'CAD';

/**
 * Format and return a currency value with a specified precision. The returned value will be
 * localized provided the current i18n locale
 * 
 * Usage:
 * 
 *   formatCurrency( 99.993, 'USD', 2 )        -> "99.99 $ US"  for locale "fr-CA"
 *   formatCurrency( 101.02, 'CAD', [0, 1] )   -> "101 $"       for locale "fr-CA"
 * 
 * @param {Number} amount              the currency amount
 * @param {String} currency            the currency locale (ex: 'USD', 'CAD', 'EUR', etc.)
 * @param {Array|Number} precision     the precision
 * @return {String}
 */
export const formatCurrency = (amount, currency, precision = 2) => {
   const locale = getLocale();
   const value = new Intl.NumberFormat(locale, { style: 'currency', currency: currency || DEFAULT_CURRENCY, ...getPrecision(precision) }).format(amount);

   if (locale.toLocaleLowerCase().includes('ca') && value.toLocaleLowerCase().includes('ca')) {
      return value.replace(/CA/ig, '').trim();
   } else {
      return value;
   }
}

/**
 * Format and return the given number with a specific precision. The returned value will be
 * localized provided the current i18n locale
 * 
 * Usage:
 * 
 *   formatNumber( 3.141592, 4 )        -> "3,1416" for locale "fr-CA"
 *   formatNumber( 3.151592, [0, 2] )   -> "3.14"   for locale "fr-CA"
 *   formatNumber( 1.0001, [1, 2] )     -> "1,0"    for locale "fr-CA"
 * 
 * @param {Number} number              the number to format
 * @param {Array|Number} precision     the precision
 * @return {String}
 */
export const formatNumber = (number, precision) => new Intl.NumberFormat(getLocale(), { style: 'decimal', ...getPrecision(precision) }).format(number);




/**
 * 
 * Usage:
 * 
 *   // Positive digits:
 *   const a = 5.467;
 *   const truncatedA = truncateNumber(a, 2);
 *   -> 5.46
 * 
 *   // Negative digits:
 *   const b = 4235.24;
 *   const truncatedB = truncateDecimals(b, -2);
 *   -> 4200
 * 
 * @param {Number} number 
 * @param {Number} precision 
 * @returns {Number}
 */
export const truncateNumber = (number, precision) => {
   const multiplier = Math.pow(10, precision);
   const adjustedNum = number * multiplier;
   const truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

   return truncatedNum / multiplier;
};