import React, {/* createContext, useContext,*/ useEffect, useMemo, useRef, useState } from 'react';
import PropType from 'prop-types';

import { initAuthenticator, useAuthenticationContext } from './authentication';

import RBACReact, { 
   //JsonRBACProvider,
   RBACProvider,
   useRBAC as __useRBAC,
   usePermissions as __usePermissions,
   usePermissionsList as __usePermissionsList
} from '@yanfoo/react-rbac-a';

import { apolloClient } from '../api';
import { gql } from '@apollo/client';



const PERMISSIONS_QUERY = gql`{ permissions }`;



// RBAC Provider
class ThermoRolesProvider extends RBACProvider {

   _collect(collected, userRoles, level) {
      const nextUserRoles = [];

      for (const userRole of userRoles) {
         const {
            name:role, 
            permissions:rolePermissions 
         } = userRole;
         const permissions = rolePermissions?.map?.(({ permission:can }) => ({ can })) ?? [];
         
         collected.push({ role, level, permissions });

         if (role?.inherited?.length) {
            nextUserRoles.push(...role.inherited);
         }
      }

      if (nextUserRoles.length) {
         this._collect(collected, nextUserRoles, level + 1);
      }

      return collected;
   }

   async getRoles(user, _options) {
      if (user?.roles?.length) {
         return this._collect([], user.roles, 1);
      } else {
         return [];
      }      
   }
}


/**
 * NOTE : it can be possible to impersonate any user by forcefully passing a specific user to this provider, 
 * which will fetch the permissions granted for that user. Note that the server will never provide access
 * that the user isn't allowed to have. In other words, impersonating is useful only when viewing components
 * from another, more restricted user.
 */
const PermissionsProvider = ({ children }) => {
   const rbacRef = useRef();
   const { user } = useAuthenticationContext();
   const [ permissionsData, setPermissions ] = useState({ ready: false, permissions: new Set() });
   
   const provider = useMemo(() => new ThermoRolesProvider(), []);
   
   const permissionsContext = useMemo(() => {
      const { ready, permissions } = permissionsData;
      
      const attributes = {};
      const options = {};
      const context = {
         //resetProvider: () => setProvider(new ThermoRolesProvider()),   // will trigger an entire page update

         /*
         Returns an iterator that will list all currently known permissions
         */
         getPermissionIterator: () => permissions.values(),
         /*
         Check if we know this permission.
         
         This function does not check if the user has a role granting this permission!!
         Check hasAccess instead.
         */
         hasPermission(permission) {
            return permissions.has(permission)
         },
         async hasAccess(permissions, options) {
            if (user?.roles?.length && user.roles.some(__isMasterRole)) {
               // special case, if we have the role with id = 1, we have all rights
               return true; 
            }

            if (typeof permissions === 'string') {
               permissions = { allowed: [ permissions ] };
            } else if (Array.isArray(permissions)) {
               permissions = { allowed: permissions };
            }
            const allowedPermissions = permissions?.allowed;
            const restrictedPermissions = permissions?.restricted;

            const allowedLevel = allowedPermissions ? await rbacRef.current.check(user, allowedPermissions, options) : NaN;
            const restrictedLevel = restrictedPermissions ? await rbacRef.current.check(user, restrictedPermissions, options) : NaN;

            const isAllowed = !isNaN(allowedLevel);

            if (isNaN(restrictedLevel)) {
               return isAllowed;
            } else if (isAllowed) {
               return allowedLevel < restrictedLevel;
            } else {
               return false;
            }
         }
      };
      
      return {
         ready,
         attributes,
         options,
         context
      };
   }, [ user, permissionsData ]);

   useEffect(() => {
      const __setPermissions = (ready, newPermissions, reset) => setPermissions(({ permissions }) => {
         if (newPermissions.size || permissions.size) {
            const merged = new Set(reset ? newPermissions : [...permissions, ...newPermissions]);

            if (merged.size !== permissions.size) {
               permissions = newPermissions;
            }
         }

         return { ready, permissions };
      });

      if (user?.id) {
         __setPermissions(false, new Set());

         initAuthenticator().then(() => apolloClient.query({ query:PERMISSIONS_QUERY, fetchPolicy: 'network-only' }).then(({ data: { permissions = [] }}) => {
            __setPermissions(true, new Set(permissions));
         })).catch(error => {
            console.error(error);
            __setPermissions(true, new Set());
         });
      } else {
         __setPermissions(true, new Set(), true);
      }
   }, [ user?.id ]);

   return (
      <RBACReact ref={ rbacRef } provider={ provider } { ...permissionsContext }>
         { children }
      </RBACReact>
   );
};

PermissionsProvider.propTypes = {
   children: PropType.node.isRequired
};


/**
 * Utility method that returns true of the given role is the master role.
 * @param {Object} role 
 * @returns 
 */
const __isMasterRole = role => role?.id ? String(role.id) === '1' && role.isProtected : false;
// proxy so we do not expose implementation
const isMasterRole = role => __isMasterRole(role);


const useRBAC = () => {
   const { user } = useAuthenticationContext();
   return __useRBAC(user);
};
const usePermissions = (permissions, options) => {
   const { user } = useAuthenticationContext(); 
   let { ready, granted } = __usePermissions(user, permissions, options);

   // i.e. if we are not granted, override if we have the master role
   if (ready && !granted && user?.roles?.length) {
      const masterLevel = user.roles.some(__isMasterRole) ? 1 : false;
      if (masterLevel) {
         granted = masterLevel;
      }
   }

   return { ready, granted };
};
const usePermissionsList = (permissionsList, options) => {
   const { user } = useAuthenticationContext();
   let { ready, granted } = __usePermissionsList(user, permissionsList, options);

   if (ready && granted?.length && user?.roles?.length) {
      const masterLevel = user.roles.some(__isMasterRole) ? 1 : false;
      if (masterLevel) {
         granted = granted.map(() => masterLevel);
      }
   }

   return { ready, granted };
}



export default PermissionsProvider;
export { 
   useRBAC,
   usePermissions,
   usePermissionsList,
   isMasterRole
};